/* Footer.css */
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #343148FF;
  color: white;
  padding-top: 4rem;
  padding-bottom: 3rem;
  box-sizing: border-box;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
  gap: 2rem;
  justify-items: center;
  margin: 0 auto;
  max-width: 1000px;
}
  
.footer-data {
  height: 100%;
  width: fit-content;
  margin: 0 auto;
}
  
.footer-title {
  font-size: 20px;
  margin: 0 0 16px 0;
  text-align: left;
}

.footer-text {
  margin: 0;
  list-style: none;
  padding: 0;
  text-align: left;
  color: #aeaeae79;
  text-decoration: none;
}

.footer-link {
  text-decoration: none;
  color: #aeaeae79;
  cursor: pointer;
}

.socials {
  font-size: larger;
  color: #aeaeae79;
  margin-right: 10px;
}
  

  