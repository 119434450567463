.projects {
    background-color: #FAFAFF;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); /* Add this line for 3 rows */
    padding: 0 0 4rem 0;
    margin: 0 auto;
    justify-items: center;
    column-gap: 5rem;
    row-gap: 5rem;
    max-width: 1000px;    
}


.project-box {
    text-align: center;
}

.project-image-placeholder {
    background-color: rgb(236, 229, 215);
    overflow: hidden;
    border-radius: 0.8rem;
    cursor: pointer;
    position: relative;
}

.project-logo {
    margin: 45px 35px -30px 35px;
    background-color: white;
    border-radius: 0.4rem;
    position: relative;
    transition: transform 0.3s ease; /* Add a transition for smooth rotation */
    height: calc(100% + 40px); /* Adjust the height to cover the bottom and extend */
    font-size: 12rem;
}

.project-logo:hover {
    transform: rotate(2.5deg) scale(1.05); /* Rotate the image slightly clockwise on hover */
}

.project-pic {
    margin-bottom: 20px;
    color:#343148FF
}

.project-content {
    margin: 16px 0;
}

.project-header {
    position: relative;
    width: 100%;
    overflow: hidden;
    opacity: 1;
    transform: none;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    font-size: 1.4rem;
}

.project-header h4 {
    font-weight: 700;
    flex-shrink: 0;
    color: #343148FF;
    max-width: calc(100% - 150px);
    margin: 0;
}

.project-line {
    width: 100%;
    height: 1px;
    background-color: #343148FF;
    opacity: .3;
}

.project-social {
    text-decoration: none;
    color: #343148FF;
}

.project-tech {
    font-size: 1rem;
}

.project-tools {
    display: flex;
    gap: 1.2rem;
    color: #343148FF;
    margin: 0.8rem 0;
    font-style: italic;
    flex-wrap: wrap;
}

.tool-box {
    padding: 5px 10px;
    background-color: rgb(236, 229, 215);
    border-radius: 0.8rem;
}

.project-tools :hover {
    background-color: #D7C49EFF;
}


.project-body {
    position: relative;
    overflow: hidden;
    opacity: 1;
    transform: none;
    color: #343148FF;
    text-align: left;
    font-size: 1.2rem;
}

.project-desc {
    margin: 0;
}

.project-desc span {
    font-weight: bolder;
    color: #343148FF;
    font-style: italic;
    margin-left: 0.5em;
}

.project-desc span:hover {
    text-decoration: underline;
    cursor: pointer;
}


