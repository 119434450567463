.section {
    padding: 4rem 0 3rem 0;
}

.section-title {
    position: relative;
    margin-bottom: 3rem;
    margin-top: 2rem;
    font-size: 2rem;
    color: #343148FF;
    text-align: center;
}

.section-title::after {
    width: 1000px;
    top: 3rem;
    position: absolute;
    content: "";
    height: 0.18rem;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #D7C49EFF;
}
