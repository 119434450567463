
.about-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem 0;
    margin: 0 auto;
    justify-items: center;
    column-gap: 2rem;
    max-width: 1000px;
}

.about-content {
    justify-self: left;
}

.about-subtitle {
    font-size: 2rem;
    color: #D7C49EFF; /* The text color */
    margin: 0 0 8px 0;
}

.about-logo {
    text-align: center;
}

.resume-file  {
    font-size: 15rem;
    color: #D7C49EFF;
}

.name {
    color: #343148FF; /* Text color */
    font-style: italic;
}
  
.social {
    color: #343148FF;
    margin-left: 10px;
    font-size: large;
}

.about-text {
    color: #343148FF;
}

.about-text span{
    font-weight: bold;
}


