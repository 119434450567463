.experience {
    background-color: #FAFAFF;
}

.experience-container {
    display: grid;
    grid-template-rows: repeat(auto, minmax(min-content, max-content)); /* Auto-adjust row size based on content height */
    padding: 0,0, 4rem 0;
    margin: 0 auto;
    align-items: center; /* Center vertically */
    row-gap: 2rem;
    max-width: 1000px;
  }
  
  
  .experience-card {
    border: 1px solid #e1e1e1;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pushes job title and bullet points to the bottom */
  }
  
  .company-title {
    display: flex;
    justify-content: space-between;
  }
  
  .role-title {
    color: #343148FF;
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    margin: 0;
  }
  
  .company {
    font-size: 16px;
    color: #666;
    margin: 5px 0;
  }
  
  .dates {
    font-size: 14px;
    color: #888;
    margin: 5px 0;
  }
  
  .bullet-points {
    color: #343148FF;
    list-style: disc;
    padding-left: 40px;
    font-style: oblique;
  }

.company-logo {
    max-width: 200px;
    max-height: 100px;
    display: block; /* To center image */
    margin: 0 auto; /* Center horizontally */
}


  /* Add any additional styling you want for your cards here */
  