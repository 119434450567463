/* Header */
.header {
    background-color: #D7C49EFF;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    line-height: 4rem;
    color: #343148FF;
}

.nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
}

.nav-logo {
    display: flex;
    text-decoration: none;
    color: #343148FF;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}


.nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-item {
    margin: 0 0 0 32px;
    text-decoration: none;
    position: relative;
}


.nav-item a {
    font-size: 20px;
    text-decoration: none;
    color: #343148FF;
    font-weight: bold;
    cursor: pointer; /* Change the cursor to pointer */
    padding-bottom: 10px;
    border-bottom: 4px solid transparent; /* Start with a transparent border */
}

.nav-item a.active {
    border-bottom-color: #343148FF; /* Apply the border color for the active item */
}

.nav-item:hover {
    transform: scale(1.1); /* Zoom effect as before */
}



