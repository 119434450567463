/* Styling for the MODAL overlay */
.modal-open {
    overflow: hidden;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: hsla(0,0%,7%,.25); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    z-index: 10000; /* Ensure the modal is on top of other content */
    cursor: pointer;
    overflow-y: scroll;
    height: 100vh;

    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #343148FF hsla(0, 0%, 7%, 0.25); /* Firefox */
}

.modal-overlay::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.modal-overlay::-webkit-scrollbar-thumb {
    background-color: #343148FF; /* Color of the thumb */
    border-radius: 4px; /* Rounded thumb corners */
}

.modal {
    width: 100%;
    background-color: white;
    border-radius: 15px;
    color: #343148FF;
    cursor:auto;
    max-width: 800px;
    height: fit-content;
    overflow: hidden;
}
  
.modal-desc {
    padding: 1.2rem; /* Apply 1.2rem padding to modal-desc */
    background-color: rgb(236, 229, 215);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}
  
  /* You can add additional styling as needed */
.modal-logo {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: 20rem; /* Adjust the font size to make it bigger */
    padding: 20px 0;
}

.modal-tool-box {
    padding: 5px 10px;
    background-color: white;
    border-radius: 0.8rem;
    border: 1px solid #343148FF;
}
  /* You can add more specific styling for h2, p, and button elements if needed */
.modal-desc h2 {
  font-size: 2rem;
}
  
.modal-desc p {
   margin: 2rem 0;
   font-weight: 500;
   font-size: 1.1rem;
}
  
.modal-desc button {
    /* Styles for button inside modal-desc */
}

.modal-desc h3 {
    margin-bottom: 10px;
}

.modal-desc h3 span{
    color: white;
    font-weight: bold;
    font-size: larger;

}


.modal-social {
    -webkit-text-stroke: 1px #343148FF; /* For compatibility with some web browsers (e.g., Safari) */
    text-decoration: none;
    color: #343148FF;
    margin-right: 10px;
}


.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #343148FF; /* Customize the color */
    padding: 0;
    z-index: 10000; /* Ensure the button is above the modal content */
  }