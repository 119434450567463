.contact-form {
    display: grid;
}

.contact-form .top-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;    
}

.credentials {
    padding: 10px;
    margin-bottom: 32px;
    border: 1px solid #D7C49EFF;
    border-radius: 5px;
    font-size: 1rem;
}
  
.message {
    grid-column: span 2; /* Span both columns in the grid */
    padding: 10px;
    margin-bottom: 32px;
    border: 1px solid #D7C49EFF;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%; /* Set width to 100% to match its parent */
    box-sizing: border-box; /* Include padding and border in the total width */
    height: 200px; /* Adjust the height to make it taller */
}


button {
    background-color: #D7C49EFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
button:hover {
    background-color: #343148FF;
}
  