.certifications-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr); /* Two rows */
    padding: 0 0 4rem 0;
    margin: 0 auto;
    justify-items: center;
    align-items: center; /* Center vertically */
    column-gap: 2rem;
    row-gap: 2rem;
    max-width: 1000px;
  }
  
  .certifications-subtitle {
    color: #343148FF;
    margin: 0 0 1.5rem 0;
    font-style: italic;
    text-align: center; /* Center horizontally */
  }
  
  .logo {
    max-height: 120px;
    display: block; /* To center image */
    margin: 0 auto; /* Center horizontally */
    cursor: pointer;
  }
  
  .certifications-progress {
    color: #343148FF;
    text-align: center; /* Center horizontally */
  }
  
  .progress-container {
    margin: 20px;
  }
  
  progress {
    width: 100%; /* Make the progress bar full width */
    height: 15px; /* Adjust the height as needed */
    appearance: none; /* Remove the default styles */
    vertical-align: middle; /* Vertically center the progress bar */
    margin-top: 10px; /* Adjust the margin to lower the position */
    border-radius: 50px; /* Adjust the value as needed */
    overflow: hidden; /* Ensure the oval shape is maintained */
    border: 1px solid #D7C49EFF;
  }
  
  progress::-webkit-progress-bar {
    background-color: #ddd; /* Background color of the progress bar */
  }
  
  progress::-webkit-progress-value {
    background-color: #D7C49EFF; /* Color of the progress */
  }
  
  .certifications-progress {
    color: #343148FF;
    text-align: center;
    font-weight: bold; /* Make the percentage bold */
  }
  
  .certification-description {
    text-align: center;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 1s ease;
  }
  
  .certifications-content.active .certification-description {
    max-height: 200px; /* Adjust the max height as needed */
    opacity: 1;
  }
  
  