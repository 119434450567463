.contact {
    background-color: #FAFAFF;
    padding-bottom: 6rem;
    padding-top: 4rem;
}

.contact-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem 0;
    margin: 0 auto;
    justify-items: center;
    column-gap: 2rem;
    max-width: 1000px;    
}

.contact-content h3{
    color: #343148FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-items {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12rem;
    color: #D7C49EFF;
}

